@media print {

  @page {
    size: A4 !important;
    margin: 10px !important;
  }

  #page-bill-pdf {
    size: A4 !important;
    page-break-before: auto;
  }

  #page-bill-pdf .header {
    width: 100%;
    text-align: center;
    height: 160px;
  }

  #page-bill-pdf .header img {
    margin-bottom: 5px;
  }

  #page-bill-pdf .header p,
  #page-bill-pdf .header h3 {
    margin: 0px !important;
  }

  #page-bill-pdf .header p,
  #page-bill-pdf .header p strong {
    font-size: 14px !important;
  }

  #page-bill-pdf .header p {
    margin: -3px !important;
  }

  #page-bill-pdf table {
    margin: 0 auto !important;
    width: 99% !important;
  }

  #page-bill-pdf table thead {
    background-color: rgb(15, 99, 130)
  }

  #page-bill-pdf table thead th {
    font-size: 13px;
    padding: 4px;
    color: #fff !important
  }

  #page-bill-pdf table thead th:nth-child(1) {
    width: 55%;
  }

  #page-bill-pdf table thead th:nth-child(2) {
    width: 15%;
    text-align: center;
  }

  #page-bill-pdf table thead th:nth-child(3) {
    width: 15%;
    text-align: center;
  }

  #page-bill-pdf table thead th:nth-child(4) {
    width: 15%;
  }

  #page-bill-pdf table tbody td:nth-child(2) {
    text-align: center;
  }

  #page-bill-pdf table tbody td:nth-child(3) {
    text-align: center;
  }

  #page-bill-pdf table tbody span {
    font-size: 13px;
    color: black;
  }

  #page-bill-pdf table tbody td {
    padding: 4px;
    border-bottom: solid 1px gainsboro;
  }

  #page-bill-pdf tfoot th:first-child {
    text-align: right;
    padding-right: 10px;
  }

  #page-bill-pdf tfoot th:last-child {
    padding-left: 5px;
  }

  #page-bill-pdf tfoot {
    border-top: solid 2px gainsboro;
  }

  #page-bill-pdf .foot {
    margin-top: 50px !important;
    border-top: solid 1px gainsboro !important;
  }

  #page-bill-pdf .foot p {
    width: 500px !important;
    margin: 0 auto !important;
    text-align: center !important;
    font-size: 13px !important;
    margin-top: 10px !important;
  }

}