#profile .nav-link {
    color: #333;
    font-size: 15px;
    width: 100%;
}

#profile .nav-link.active {
    color: #0f6382;
    border-left: solid 3px #0f6382
}

#profile .card-body h5 {
    font-size: 17px;
    padding-bottom: 5px;
    border-bottom: solid 1px rgba(231,234,243,.7);
}

#profile .password h6 {
    padding-top: 20px;
    font-weight: bold;
    border-top: solid 1px rgba(231,234,243,.7);
}

#profile .password h6, #profile .password li, #profile .password p {
    font-size: 14px;
}