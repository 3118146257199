/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
    padding: 20px 0;
    font-size: 14px;
    transition: all 0.3s;
    border-top: 1px solid #f2f2f2;
  }
  
  .footer .copyright {
    text-align: center;
    color: #333;
  }
  
  .footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #333;
  }