#header .profile-name {
    border-right: dashed 1px gainsboro;
    padding-right: 15px;
    margin-right: 15px;
}

#header .header-nav .nav-profile span {
    font-size: 15px;
}

#header .header-nav .nav-profile span i {
    font-size: 18px;
}