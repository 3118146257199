.head-totals label,
.head-totals span {
    display: block;
    font-size: 15px;
}

.head-totals label {
    color: #677788;
}

.head-totals span {
    font-weight: bold;
}

.head-totals h5 {
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: dashed 1px gainsboro;
}