#md-pay-off-debt .form-payment-debt .col-md-12 {
    text-align: left;
}

#md-pay-off-debt .form-payment-debt .col-md-12:first-child {
    text-align: center;
}

#md-pay-off-debt .form-payment-debt .col-md-12:first-child > .form-control {
    width: 130px;
    margin: 0 auto;
    text-align: center;
}

#md-pay-off-debt .form-payment-debt textarea {
    height: 100px;
}