#md-manager-stock .row:nth-child(3) .form-control {
    text-align: center;
}

#md-manager-stock .row:nth-child(4) .form-control {
    height: 80px !important;
}


#md-manager-stock h5 {
   font-size: 15px;
   color: #333;
   font-weight: bold;
   border-bottom: dashed 1px gainsboro;
}