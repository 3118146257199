#sign-in .card-body {
    padding: 50px !important;
}

#sign-in .card-body .logo-sign-in {
    text-align: center;
    margin-bottom: 20px;
}

#sign-in .card-body p.footer-sign-in {
    width: 320px;
    font-size: 14px;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
}

#sign-in .card-body p.title-sign-in {
    text-align: center;
    font-weight: bold;
    font-size: 17px !important;
}

#sign-in .form-check-label {
    font-size: 14px;
}