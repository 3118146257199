#md-supplier .payment-place {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
}

#md-supplier .payment-place input { 
    width: 65px;
}

#md-supplier .payment-place span { 
    margin-left: 10px;
}
