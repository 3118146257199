@media print {

    * {
        color: black !important;
        font-size: 13px !important;
        font-style: normal !important;
        font-family: 'Arial' !important;
    }

    @page {
        size: 80mm;
        margin: 0mm;
    }

    #invoice-print table {
        width: 80mm;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #invoice-print .block {
        display: block;
    }

    #invoice-print .cancel-invoice {
        padding: 5px;
        margin-top: 5px;
        text-align: center;
        background-color: gainsboro;
    }

    #invoice-print table thead,
    #invoice-print table tfoot {
        border-top: solid 1px gray;
        border-bottom: solid 1px gray;
    }

    #invoice-print table tbody tr td {
        vertical-align: top;
    }

    #invoice-print .head-ncf {
        margin-top: 10px;
        margin-bottom: 10px;
        border-top: solid 1px gray;
        border-bottom: solid 1px gray;
    }

    #invoice-print h6,
    #shift-closure-print h6 {
        text-align: center;
        font-size: 15px !important;
        font-weight: bold;
    }

    #invoice-print .thanks {
        text-align: center;
        display: block;
        margin: 0px;
        padding: 0px;
    }

    #invoice-print .head-inv img {
        width: 250px;
        margin-bottom: 10px;
    }

    #invoice-print .head-inv,
    #shift-closure-print .head-inv {
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    #invoice-print table .align-right {
        text-align: right;
    }

    #shift-closure-print h6 {
        padding: 3px;
        background-color: gainsboro;
    }

    #shift-closure-print table {
        width: 100% !important;
    }

    #shift-closure-print table tr td,
    #shift-closure-print table tr th {
        text-align: center;
        font-size: 14px;
    }

    #shift-closure-print .row-prt, #invoice-print .row-prt {
        width: 100%;
        margin-bottom: 10px;
    }

    #shift-closure-print .row-prt-line {
        border-bottom: solid 1px #333;
        padding-bottom: 10px;
    }

    #shift-closure-print .row-prt-dash {
        border-bottom: dashed 1px #333;
        padding-bottom: 10px;
    }

    #shift-closure-print .row-prt div,
    #shift-closure-print .row-prt-3 div,
    #shift-closure-print .row-prt-4 div,
    #invoice-print .row-prt div {
        width: 50%;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
    }

    #shift-closure-print .row-prt-3 div {
        width: 33.33%;
    }

    #shift-closure-print .row-prt-4 div { 
        width: 25%; 
    }

    #shift-closure-print .row-prt div span,
    #shift-closure-print .row-prt div strong,
    #shift-closure-print .row-prt-3 div span,
    #shift-closure-print .row-prt-3 div strong,
    #shift-closure-print .row-prt-4 div span,
    #shift-closure-print .row-prt-4 div strong,
    #invoice-print .row-prt div span,
    #invoice-print .row-prt div strong{
        display: block;
        width: 100%;
    }

    #invoice-print .mb-4 {
        margin-bottom: 20px;
    }

    #invoice-print .qr-code {
        display: block !important;
        text-align: center !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
}