@media print {

  @page {
    size: A4 landscape !important;
    margin: 10px !important;
  }

  #page-pdf {
    size: A4 landscape !important;
    page-break-before: auto;
  }

  #page-pdf .header {
    width: 100%;
    text-align: center;
    height: 170px;
  }

  #page-pdf .header img {
    margin-bottom: 5px;
    border-radius: 50%;
  }

  #page-pdf .header h4 {
    margin: 0px;
    margin-top: 5px;
  }

  #page-pdf .header h5 {
    margin: 0px;
  }

  #page-pdf .header h4 strong,
  #page-pdf .header h5,
  #page-pdf .header h5 strong,
  #page-pdf .header div,
  #page-pdf .header div strong {
    font-size: 15px !important;
  }

  #page-pdf table {
    margin: 0 auto !important;
    width: 99% !important;
  }

  #page-pdf table thead {
    background-color: rgb(15, 99, 130)
  }

  #page-pdf table thead th {
    font-size: 13px;
    padding: 4px;
    color: #fff !important
  }

  #page-pdf table tbody span {
    font-size: 13px;
    color: black;
  }

  #page-pdf table tbody td {
    padding: 4px;
    border-bottom: solid 1px gainsboro;
  }

  #page-pdf .total-amount {
    margin-top: 30px;
    width: 50%;
  }

  #page-pdf .total-amount h3 {
    font-weight: bold;
    margin-left: 5px !important
  }

  #page-pdf .total-amount table tbody td {
    font-weight: bold;
  }

}