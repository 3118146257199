#dashboard .icon, #dashboard .text {
    display: inline-block;
    vertical-align: middle;
}

#dashboard .options .icon {
    padding: 10px;
    margin-right: 20px;
    border-radius: 10px;
}

#dashboard .row:nth-child(1) > .col:nth-child(1) .options .icon {
    color: #198754;
    background-color: rgba(25, 135, 84, 0.278);
}

#dashboard .row:nth-child(1) > .col:nth-child(2) .options .icon {
    color: #2d6afb;
    background-color: rgba(45, 106, 251, 0.278);
}

#dashboard .row:nth-child(1) > .col:nth-child(3) .options .icon {
    color: #0f6382;
    background-color: rgba(15, 99, 130, 0.278);
}

#dashboard .row:nth-child(1) > .col:nth-child(4) .options .icon {
    color: #dc3545;
    background-color: rgba(220, 53, 69, 0.278);
}

#dashboard .options .icon i {
    font-size: 30px;
}

#dashboard .options .text label, #dashboard .text span {
    display: block;
}

#dashboard .options .text label {
    font-size: 17px;
    font-weight: bold;
}