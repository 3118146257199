#sidebar .sidebar-nav .nav-item {
    border-bottom: dashed 1px gainsboro;
}

#sidebar .sidebar-nav .nav-item:last-child {
    border-bottom: none;
}

#sidebar .nav-item span {
    font-size: 15px;
    width: 100%;
    font-weight: normal;
    color: #333;
}

#sidebar .sidebar-nav .nav-link i {
    color: #0f6382;
}

#sidebar #components-nav .active>span {
    font-weight: bold !important;
}

#sidebar #components-nav .active>i {
    color: gainsboro
}