#loading-modal {
    display: block;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(70, 70, 70, 0.5);
}

#loading-modal .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50%;
    left: 41%;
    color: white;
    transform: translateY(-50%);
    width: 300px;
    height: 100px;
    border-radius: 5px;
    padding: 20px;
}

#loading-modal .modal-content p {
    margin-top: 60px;
    text-shadow: 1px 1px 1px rgba(58, 58, 58, 0.5);
}