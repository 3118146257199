#new-supplier-invoice .form-label {
    display: block;
}

#new-supplier-invoice .totals .form-label,
#new-supplier-invoice .totals span {
    display: inline-block;
    vertical-align: middle;
}

#new-supplier-invoice .totals .col-md-12 div {
    text-align: right;
}

#new-supplier-invoice .totals span {
    padding-left: 10px;
    font-style: italic;
    font-size: 16px;
}

#new-supplier-invoice .totals .col-md-12:nth-child(3) {
    color: gray;
}

#new-supplier-invoice .totals .gap-2 {
    border-top: dashed 1px gainsboro;
    padding-top: 5px;
    margin-top: 5px;
}

#new-supplier-invoice textarea[name="comment"] {
    height: 143px;
}