#view-supplier-invoice .form-label {
    display: block;
}

#view-supplier-invoice .totals .form-label,
#view-supplier-invoice .totals span {
    display: inline-block;
    vertical-align: middle;
}

#view-supplier-invoice .totals .col-md-12 div {
    text-align: right;
}

#view-supplier-invoice .totals span {
    padding-left: 10px;
    font-style: italic;
    font-size: 16px;
}

#view-supplier-invoice .totals .col-md-12:nth-child(3) {
    color: gray;
}

#view-supplier-invoice .totals .gap-2 {
    border-top: dashed 1px gainsboro;
    padding-top: 5px;
    margin-top: 5px;
}

#view-supplier-invoice .info-span {
    font-size: 15px;
}

#view-supplier-invoice h5 {
    margin-bottom: 0;
}

#view-supplier-invoice p.comment {
    overflow: auto;
    height: 154px;
    margin: 0;
    padding: 3px;
}