#point-of-sale {
    padding-top: 5px;
}

#point-of-sale .alert {
    text-align: center;
}

#point-of-sale .body-table-items {
    height: 52vh;
}

#point-of-sale .table-items thead,
#md-employee .table-items thead {
    background-color: #0f6382;
}

#point-of-sale .table-items .selected {
    background-color: #ddedff;
}

#point-of-sale .table-items tr th,
#md-employee .table-items tr th {
    color: white;
}

#point-of-sale .table-items tr th:nth-child(1) {
    min-width: 8%;
}

#point-of-sale .table-items tr th:nth-child(2) {
    min-width: 20%;
}

#point-of-sale .table-items tr th:nth-child(3) {
    min-width: 10%;
    text-align: center;
}

#point-of-sale .table-items tr th:nth-child(4) {
    min-width: 11%;
    text-align: center;
}

#point-of-sale .table-items tr th:nth-child(5) {
    min-width: 10%;
    text-align: center;
}

#point-of-sale .table-items tr th:nth-child(6) {
    min-width: 8%;
    text-align: center;
}

#point-of-sale .table-items tr th:nth-child(7) {
    min-width: 8%;
    text-align: center;
}

#point-of-sale .table-items tr th:nth-child(8) {
    min-width: 10%;
    text-align: center;
}

#point-of-sale .table-items tr th:nth-child(9) {
    min-width: 10%;
}

#point-of-sale .table-items tr td {
    vertical-align: middle;
    font-size: 15px !important
}

#point-of-sale .table-items tr td:nth-child(3),
#point-of-sale .table-items tr td:nth-child(4),
#point-of-sale .table-items tr td:nth-child(5),
#point-of-sale .table-items tr td:nth-child(6),
#point-of-sale .table-items tr td:nth-child(7),
#point-of-sale .table-items tr td:nth-child(8) {
    text-align: center;
}

#point-of-sale .table-items tr td:nth-child(4) span {
    width: 43.8px;
    text-align: center;
    display: inline-block
}

#point-of-sale .table-items tr td:nth-child(4) {
    padding-left: .15rem;
    padding-right: .15rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
}

#point-of-sale .table-items tbody input[type="text"] {
    width: 100%;
    height: 31px;
    border: none;
    text-align: center;
    background: transparent;
}

#point-of-sale .table-items tbody input[type="text"]:focus {
    outline: none;
}

#point-of-sale .table-items-option {
    width: 112px;
    margin: 0 auto;
}

#point-of-sale .table-items-option span {
    display: inline-block;
}

#point-of-sale .card-body {
    padding: 20px;
}

#point-of-sale .head-block {
    margin-top: 15px;
}

#point-of-sale .head-block .card {
    height: 90px;
    margin-bottom: 20px;
}

#point-of-sale .head-block strong,
#point-of-sale .head-block label {
    display: block;
}

#point-of-sale .head-block .totals-block strong,
#point-of-sale .head-block .totals-block label {
    text-align: center;
}

#point-of-sale .totals-block .col-3:nth-child(2) label {
    color: gray
}

#point-of-sale .totals-block .col-3:nth-child(4) label {
    color: #198754;
    font-weight: bold;
    font-size: 21px;
}

#point-of-sale .footer-pos {
    margin-bottom: 20px;
}

#point-of-sale .search-product {
    width: 75%
}

#md-payment-options h6 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: #f2f2f2;
    border: dashed 1px gainsboro;
}

#md-payment-options .payment-method button span {
    display: block;
    margin-top: 5px;
}

#md-payment-options .payment-method button i {
    color: #0f6382;
}

#md-payment-options .payment-method button:disabled {
    background-color: gainsboro;
    opacity: 1
}

#md-payment-options .payment-method .btn.active {
    background-color: #0f6382 !important;
    border-color: #0f6382 !important;
    color: #fff;
    opacity: 1
}

#md-payment-options .payment-method .btn.active i {
    color: #fff;
}

#md-payment-options .option-cash {
    text-align: center;
}

#md-payment-options .option-cash .row {
    width: 400px;
    margin: 0 auto;
}

#md-payment-options .option-cash .dollars .form-control {
    width: 75px;
    padding: .875rem .3rem;
    display: inline-block;
    vertical-align: middle;
}

#md-payment-options .option-cash .dollars .form-control:last-child {
    width: 95px;
}

#md-payment-options .option-cash,
#md-payment-options .option-card,
#md-payment-options .option-credit,
#md-payment-options .option-transfer,
#md-payment-options .option-combined {
    padding-top: 15px;
}

#md-payment-options .option-cash h6,
#md-payment-options .option-card h6,
#md-payment-options .option-transfer h6,
#md-payment-options .option-credit h6 {
    margin-bottom: 15px;

}

#md-payment-options .option-card .row,
#md-payment-options .option-credit .row,
#md-payment-options .option-transfer .row {
    width: 500px;
    margin: 0 auto;
}

#md-payment-options .option-transfer,
#md-payment-options .option-card {
    text-align: center;
}

#md-payment-options .option-transfer label,
#md-payment-options .option-cash label,
#md-payment-options .option-card label {
    font-size: 1rem !important;
    display: block !important;
}

#md-payment-options .option-transfer .form-control,
#md-payment-options .option-cash .form-control,
#md-payment-options .option-card .form-control,
#md-payment-options .option-credit .form-control {
    text-align: center;
    font-size: 16px !important
}

#md-payment-options .modal-body {
    text-align: center;
}

#md-payment-options .btn-success {
    margin-top: 10px;
}

#md-customers form {
    width: 550px;
}

/** Modal list products **/
#md-list-products form {
    width: 500px;
}

/** Modal shift **/
#md-open-shift .modal-body div {
    width: 250px;
    text-align: center;
    margin: 0 auto;
}

#md-open-shift .modal-body div label {
    font-size: 17px;
}

#md-open-shift .modal-body div .form-control {
    font-size: 20px !important;
    text-align: center;
}

#md-product-information h5 {
    font-size: 16px;
    font-weight: bold;
    border-bottom: dashed 1px gainsboro;
}

#md-pay-off-debt {
    text-align: center;
}

#md-pay-off-debt .payment-debt {
    width: 180px;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
}

#md-pay-off-debt .col-md-4:nth-child(2) .view-span {
    color: #198754;
}

#md-pay-off-debt .col-md-4:nth-child(3) .view-span {
    color: #dc3545;
}

#md-pay-off-debt .payment-debt .form-control {
    text-align: center;
    font-size: 16px !important
}

#md-outstanding-debts .col-md-4,
#md-history-outstanding-debts .col-md-4 {
    text-align: center;
}

#md-outstanding-debts .col-md-4:nth-child(1) .view-span,
#md-history-outstanding-debts .col-md-4:nth-child(1) .view-span {
    color: #02839b;
}

#md-outstanding-debts .col-md-4:nth-child(2) .view-span,
#md-history-outstanding-debts .col-md-4:nth-child(2) .view-span {
    color: #198754;
}

#md-outstanding-debts .col-md-4:nth-child(3) .view-span,
#md-history-outstanding-debts .col-md-4:nth-child(3) .view-span {
    color: #dc3545;
}

#md-payment-options .total-option .col-4 .form-label {
    display: block;
    font-size: 18px;
}

#md-payment-options .total-option .col-4 strong {
    display: block;
    font-size: 18px;
}

#md-payment-options .total-option .col-4:nth-child(1) strong {
    color: #0f6382;
}

#md-payment-options .total-option .col-4:nth-child(2) strong {
    color: #dc3545;
}

#md-payment-options .total-option .col-4:nth-child(3) strong {
    color: #198754;
}

#md-payment-options .modal-footer .btn-success {
    margin: 0 auto;
}

#md-payment-options .modal-footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: dashed 1px gainsboro;
}

#md-close-shift hr,
#md-view-close-shift hr {
    opacity: 1;
    border-top: dashed 1px gainsboro !important
}

#md-close-shift .row .col-md-2,
#md-close-shift .row .col-md-3,
#md-close-shift .row .col-md-4,
#md-view-close-shift .row .col-md-2,
#md-view-close-shift .row .col-md-3,
#md-view-close-shift .row .col-md-4 {
    text-align: center;
}

#md-close-shift .row:last-child .col-md-4,
#md-view-close-shift .row:last-child .col-md-4 {
    text-align: center;
}

#md-close-shift .form-control {
    text-align: center;
    max-width: 150px;
    margin: 0 auto;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 16px !important;
}

#md-close-shift .currency {
    margin-top: 20px;
    margin-bottom: 20px;
}

#md-close-shift .currency h6,
#md-view-close-shift h6 {
    text-align: center;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-top: dashed 1px gainsboro;
    border-bottom: dashed 1px gainsboro;
}

#md-box-closures form {
    width: 200px;
}

#md-bills form {
    width: 550px;
}

#md-employee .modal-footer {
    text-align: center;
    align-items: center;
}

#md-employee .table-items tr td {
    vertical-align: middle;
    font-size: 15px !important
}

#md-employee .services-table-wrap {
    min-height: 300px;
}

small {
    font-size: 13px !important;
}

.alert-danger {
    text-align: center !important;
    font-size: 14px;
    padding: 6px;
}